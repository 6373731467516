<template>
  <f7-page class="weeklyrecommend">
    <template #fixed>
      <HomeNavigationComponent search="weeklyrecommendsearch" />
    </template>
    <section class="">
      <div class="">
        <div v-if="isGettingList" class="preloader-cont">
          <f7-preloader></f7-preloader>
        </div>

        <div v-if="productList.length" class="">
          <div class="product-container">
            <template v-for="(product, i) in productList" :key="'rc_' + i">
              <ProductListCardComponent :data="product" :f7router="f7router" :category="true" :isShop="true" />
            </template>
          </div>
        </div>

        <NoDataFoundComponent v-if="!isGettingList && productList.length == 0" :size="'sm'" type="full" :title="$t.getTranslation('LBL_NO_PRODUCT_FOUND')" />
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, computed, inject, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";
// import HomeNavigationComponent from "@/components/navigations/HomeNavigationComponent.vue";
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

export default defineComponent({
  name: "WeeklyRecommendPage",
  components: {
    NoDataFoundComponent: defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue")),
    HomeNavigationComponent: defineAsyncComponent(() => import(/* webpackChunkName: "home-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/HomeNavigationComponent.vue")),
    ProductListCardComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-list" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue")),
  },
  props: { f7router: Object, f7route: Object },
  setup() {
    const store = useStore();

    let isGettingList = ref(true);
    const productList = ref([]);
    const showPreloader = ref(false);
    const $t = inject("$translation");
    const user = computed(() => store.getters["user/getData"]);

    let page = 1;
    let size = 30;
    let lastPage = 1;

    const getProductList = async (isDefault) => {
      let obj = {
        page: page,
        size: size,
        LanguageCode: $t.getLanguage(),
        mode: "WEEKLYRECOMMEND",
      };

      let url = extendedConfigs?.isElastic ? `/mobile/product/elastic/list` : `mobile/product/public/list`;
      let ret = await get(url, obj);
      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          if (extendedConfigs?.isElastic) {
            var productAttribute = item.AttributeList.filter(function (attr) {
              if (attr.LanguageCode == languageCode.value) {
                return attr;
              }
            });
            let pickAttr = _.pick(productAttribute[0], ["Name", "Description"]);
            item = { ...item, ...pickAttr };
          }
          productList.value.push(item);
        }
        lastPage = ret?.lastPage;
        page++;
      }

      isGettingList.value = false;
    };

    const loadMore = () => {
      return lastPage >= page ? getProductList() : false;
    };

    onMounted(() => {
      getProductList();
    });

    return { productList, isGettingList, loadMore, showPreloader };
  },
});
</script>

<style>
.bg-gray {
  width: 100%;
  min-height: 735px;
}
.weeklyrecommend-container > .container {
  margin: 10px 10px;
}

.product-container {
  /* padding-right: 30px; */
  padding-left: 10px;
  padding-top: 10px;
}
</style>
